// Chakra imports
import { SimpleGrid, Text, useColorModeValue,Tabs, TabList, TabPanels,Tab,
  TabPanel } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import ComplexTable from "./ComplexTable";


import { withTranslation } from 'react-i18next';

// Assets
function Notifications(props) {
  const { ...rest } = props;
  const {t } = props;

  var [unreadNotifList, setUnreadNotifList] = React.useState([]);
  var [readNotifList, setReadNotifList] = React.useState([]);
  
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");

  const columnsData = [
    {
      Header: "Notification",
      accessor: "title",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Action",
      accessor: "id",
    },
  ];

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='25px'>
        Notifications
      </Text>
      
        <Tabs zindex="0" variant='soft-rounded' colorScheme='teal'>
          <TabList>
            <Tab> {t("unread")}</Tab>
            <Tab> {t("read")}</Tab>
          </TabList>
          <TabPanels w='100%' align="center" justify="center">
            <TabPanel w='100%'>
              <SimpleGrid columns='1' gap='10px'>
                <ComplexTable
                  title={''}
                  columnsData={columnsData}
                  tableData={unreadNotifList}
                  onDelete={(row)=>{
                    
                  }}
                  onModify={(row)=>{ 
                    //editRow(row) 
                  }}
                />
              </SimpleGrid>
            </TabPanel>
            <TabPanel  w='100%'>
              <SimpleGrid columns='1' gap='10px'>
                <ComplexTable
                    title={''}
                    columnsData={columnsData}
                    tableData={readNotifList}
                    onDelete={(row)=>{
                      
                    }}
                    onModify={(row)=>{ 
                      //editRow(row) 
                    }}
                  />
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>

    </Card>
  );
}
export default withTranslation()(Notifications);