/*!
                                                                                                                                                                                                                                                                                                                                   
=========================================================
* Horizon UI - v1.1.0
=========================================================

*/

import React from "react";
// Chakra imports
import { Box,Tab,Tabs,TabList,
  TabPanels,SimpleGrid,TabPanel,Flex} from "@chakra-ui/react";
import {useDisclosure} from "@chakra-ui/hooks";
import { withTranslation } from 'react-i18next';
// Custom components
import Alerts from "./components/alert";
import Automating from "./components/automating";
import ComplexTable from "./components/ComplexTable";
// Redux
import { useSelector} from 'react-redux';

import * as R from 'ramda';
import { useHistory } from "react-router-dom";
import { Post } from "utils/queries";

import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import ModalBillingConfig from './components/billing/modal/ModalBillingConfig';

import Pairing from "./components/billing/Pairing";

function Configuration(props) {
  const {t}=props;
  const myInfos = useSelector(state => state.user.infos);
  const cur_device = useSelector(state => state.device.cur_device);

  const [billingConfig, setBillingConfig] = React.useState([]);
  const [billingConfigAll, setBillingConfigAll] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const {isOpen, onOpen, onClose } = useDisclosure()
  var [sensorsList, setSensorsList] = React.useState([]);

  const [profile, setProfile] = React.useState("{}");
  

  let _billingConfig,response,history = useHistory();

  const deleteRow =async (row)=> {
    setIsLoading(true)
    response=await Post.requestPost("manageBillingPlan",{token:myInfos.token,remove_id:row.id})
    if(response.hasOwnProperty("error")){
      if(response.error===200) {
        setBillingConfig(R.filter(o => o.id !== row.id, billingConfig))  
        setBillingConfigAll(R.filter(o => o.id !== row.id, billingConfigAll)) 
        NotificationManager.success(t("successful_operation"), "Message");
      }else if(response.hasOwnProperty("msg")) NotificationManager.error(response.msg, "Message")
      else NotificationManager.error(t("error_occurred"), "Message")
    }else NotificationManager.error(t("error_occurred"), "Message")
    setIsLoading(false)
  };

  const editRow =(row)=> {
    setProfile(JSON.stringify(row));
    onOpen()
  }
  React.useEffect(async() => { 
    document.title = "Configuration - NeoLec";
    if(myInfos.token){ 
    }else history.push('/')

    setIsLoading(true)
    response=await Post.requestPost("manageBillingPlan",{token:myInfos.token,read_id:""})
    if(response.hasOwnProperty("error")){
      if(response.error===200) {
        setBillingConfig(response.items)  
        setBillingConfigAll(response.items) 
      }else if(response.hasOwnProperty("msg")) NotificationManager.error(response.msg, "Message")
      else NotificationManager.error(t("error_occurred"), "Message")
    }else NotificationManager.error(t("error_occurred"), "Message")

    
    response=await Post.requestPost("listSensors",{token:myInfos.token,"device_id":cur_device.value})
    if(response.hasOwnProperty("error")) NotificationManager.error(response.msg, 'Message');
    else setSensorsList(response)

    setIsLoading(false)

    return () => {}
  },[]);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text={t("loading")}
    >
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {/* Configuration */}
        <Tabs bg="white" variant='enclosed'>
          <TabList>
            <Tab> {t("billing")}</Tab>
            <Tab> {t("alert")+"s"}</Tab>
              <Tab> {t("automating")}</Tab>
          </TabList>
          <TabPanels w='100%' align="center" justify="center">
            <TabPanel>
              <Tabs bg="white">
                <TabList>
                  <Tab> {t("Model")+"s"}</Tab>
                  <Tab> {t("Pairing")}</Tab>
                </TabList>
                <TabPanels w='100%' align="center" justify="center">
                  <TabPanel>
                    <Flex
                      flexDirection='column'
                      gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
                      <ComplexTable
                        title={t("saved models")}
                        columnsData={[
                          {Header: t("title"), accessor: "title"},
                          {Header: t("currency"),accessor: "currency"},
                          {Header: "Action",accessor: "id"}
                        ]}
                        tableData={billingConfig}
                        onSearch={(text)=>{
                          if(text.length===0) setBillingConfig(billingConfigAll)
                          else setBillingConfig(R.filter(o => JSON.stringify(o).toLowerCase().includes(text.toLowerCase()), billingConfigAll)) 
                        }}
                        onDelete={(row)=>{
                          NotificationManager.error(t("click_to_confirm"), t("deleting"), 5000, () => {
                            deleteRow(row)
                          });
                        }}
                        onModify={(row)=>{editRow(row)  }}
                        onOpen={() => {
                          setProfile("{}");
                          setTimeout(()=>{
                            onOpen()
                          },1000);
                        }}
                      />
                    </Flex> 
                  </TabPanel>
                  <TabPanel>
                    <Pairing
                      billingList={billingConfigAll}
                      showErrorMsg={(msg)=>{NotificationManager.error(msg, 'Message'); }}
                      onSubmit={(_datas)=>{
                        ///
                      }}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={{base:1,md:1,xl:1}} gap='20px'>
                <Alerts
                  myInfos={myInfos}
                  cur_device={cur_device}
                  title={t("Configured alerts")}
                  sensorsList={sensorsList}
                  columnsData={[
                    {Header: t("title"), accessor: "alertTitle"},
                    {Header: t("sensor"), accessor: "title"},
                    {Header: "Type",accessor: "alertType"},
                    {Header: "Action",accessor: "_id"}
                  ]}
                  responseMsg={(state,msg)=>{
                    if(state)NotificationManager.success(msg?msg:t("successful_operation"), 'Message');
                    else NotificationManager.error(msg, 'Message');
                  }}
                />
              </SimpleGrid>
            </TabPanel>
            <TabPanel>
              <SimpleGrid columns={{base:1,md:1,xl:1}} gap='20px'>
                <Automating
                  myInfos={myInfos}
                  cur_device={cur_device}
                  sensorsList={sensorsList}
                  title={t("automating")}
                  columnsData={[
                    {Header: t("title"), accessor: "title"},
                    {Header: "NB. Conditions", accessor: "condition"},
                    {Header: "Nb. Actions",accessor: "action"},
                    {Header: "Action",accessor: "_id"}
                  ]}
                  responseMsg={(state,msg)=>{
                    if(state)NotificationManager.success(msg?msg:t("successful_operation"), 'Message');
                    else NotificationManager.error(msg, 'Message');
                  }}
                />
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <ModalBillingConfig
          isOpen={isOpen} 
          datas={JSON.parse(profile)}
          onClose={onClose}
          onSubmit={async(billing)=>{
            onClose()
            setIsLoading(true)
            billing=JSON.parse(billing)
            response=await Post.requestPost("manageBillingPlan",{token:myInfos.token,billing})
            if(response.hasOwnProperty("error")){
              if(response.error===200){
                if(billing.hasOwnProperty("id")){
                  _billingConfig=billingConfig
                  for (let i = 0; i < _billingConfig.length; i++) {
                    if(_billingConfig[i].id===billing.id){
                      _billingConfig[i]=billing
                      break
                    }
                  }
                  setBillingConfig(_billingConfig)
                  setBillingConfigAll(_billingConfig)
                  NotificationManager.success(t("successful_modification"), "Message");
                }else{
                  billing.id=response.id
                  setBillingConfig([...billingConfig,billing])
                  setBillingConfigAll([...billingConfigAll,billing])
                  NotificationManager.success(t("successfully_added"), "Message");
                }
                onClose()
              }
              else if(response.hasOwnProperty("msg")){
                onOpen()
                NotificationManager.error(response.msg, "Message")
              }
              else NotificationManager.error(t("error_occurred"), "Message")
            }else NotificationManager.error(t("error_occurred"), "Message")

            setIsLoading(false)
          }}
          showMsg={(msg)=>{NotificationManager.success(msg, 'Message'); }}
          showErrorMsg={(msg)=>{NotificationManager.error(msg, 'Message'); }}
        />
        <NotificationContainer/>
      </Box>
    </LoadingOverlay>
  );
}
export default withTranslation()(Configuration);
