import {
  Flex,Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,

  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,

  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton
} from '@chakra-ui/react'
import Select from 'react-select';

import { withTranslation } from 'react-i18next';
import React from "react";

import {validateField} from "utils/Validators";
import {Post} from '../queries'

function ModalForm(props) {
  const {t,isOpen, onClose,token,role,addDevice,showMsg,showErrorMsg,data}=props;
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const bgColor = useColorModeValue("secondary.dark","navy.800");

  const [values, setValues] = React.useState({
    id: "",
    title:"",
    forecast_id:"",
    rate:"",
    device:"",
    idDevice:"",
    isLoading:false
  });
  
  var [errorID, setErrorID] =  React.useState("");
  var [errorTitle, setErrorTitle] =  React.useState("");
  var response;

  React.useEffect(() => { 
    setValues(data)
    return () => {}
  },[data]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async (e) => { 
    e.preventDefault();

    let error,noFound=true;
    //////////
    error= await validateField(values.device===""?"":values.device.value)
    if(error.field)setErrorID("")
    else{
      setErrorID(error);
      noFound=false;
    }
    error= await validateField(values.title)
    if(error.field)setErrorTitle("")
    else{
      setErrorTitle(error);
      noFound=false;
    }
    
    if(noFound) {
      setValues({ ...values,"isLoading":true})

      var _device={ 
        id:values.device.value, 
        title:values.title,
        token,
        date_msg:"--"
      }
      if(values.idDevice!=="")_device.idDevice=values.idDevice
      if(values.forecast_id!=="")_device.forecast_id=values.forecast_id
      if(values.rate!=="")_device.rate=values.rate
      
      
      response=await Post.requestPost("addDevice",_device)
      if(response.hasOwnProperty("error")){
        if(response.error===200){
          if(values.idDevice===""){
            _device._id=response.id
            showMsg(t("successfully_added"))
          }else{
            _device._id=values.idDevice
            showMsg(t("successful_modification"))
          }
          addDevice(_device)
          ////
          setValues({...values,
            idDevice:"",
            id: "",
            title: "",
            forecast_id:"",
            rate:"",
            device:"",
            isLoading:false
          })
          onClose();
        } 
        else if(response.error===402) showErrorMsg(t("identifier_already_used"))
        else if(response.hasOwnProperty("msg"))showErrorMsg(response.msg)
        else showErrorMsg(t("error_occurred"))
      }else showErrorMsg(t("error_occurred"))
      setValues({ ...values,"isLoading":false})
    }
  }

  return (

    <Modal isCentered isOpen={isOpen} onClose={onClose}>
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(10px) hue-rotate(90deg)'
    />
   
    <ModalContent>
      <ModalHeader>Informations</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex
          zIndex='2'
          direction='row'
          mx={{ base: "auto", lg: "unset" }}
          mb={{ base: "2px", md: "auto" }}>
          <FormControl isRequired  isInvalid={errorID!==""}>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("id")}
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder={t("id")}
              mb='9px'
              fontWeight='500'
              value={values.device?.value}
              onChange={(itm)=>{setValues({ ...values, "device": {label:itm.target.value,value:itm.target.value} });}}
              size='lg'
            />
            <FormErrorMessage>{errorID}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={errorTitle!==""} ml='10px' >
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("title")}
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder={t("title")}
              mb='9px'
              fontWeight='500'
              value={values.title}
              onChange={handleChange('title')}
              size='lg'
            />
            <FormErrorMessage>{errorTitle}</FormErrorMessage>
          </FormControl>
        </Flex>
        {role==="admin"?<Flex
          zIndex='2'
          direction='row'
          mx={{ base: "auto", lg: "unset" }}
          mb={{ base: "2px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("forecast_sync_id")}
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder={t("forecast_sync_id")}
              mb='9px'
              fontWeight='500'
              value={values.forecast_id}
              onChange={handleChange('forecast_id')}
              size='lg'
            />
          </FormControl>
          <FormControl ml='10px'>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='0px'>
              {t("multiplier")}
            </FormLabel>
            <Input
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              placeholder={t("multiplier")}
              mb='9px'
              fontWeight='500'
              value={values.rate}
              onChange={handleChange('rate')}
              size='lg'
            />
          </FormControl>
        </Flex>:null}
      </ModalBody>
      <ModalFooter bg={bgColor}>
        <Flex w="100%" justify="space-between">
          {values.idDevice===""?<Button colorScheme='red' variant='outline' 
            onClick={()=>{
              setValues({...values,
                idDevice:"",
                id: "",
                title: "",
                device:"",
                forecast_id:"",
                rate:"",
                isLoading:false
              })
              onClose();
            }}>{t("cancel")}</Button>:
          <Popover>
            <PopoverTrigger>
              <Button>{t("last_message")}</Button>
            </PopoverTrigger>
            <PopoverContent w='600px'>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>{data.last_message}</PopoverHeader>
              <PopoverBody>{JSON.stringify(data.message)}</PopoverBody>
            </PopoverContent>
          </Popover>}
          <Button  
            width='140px' 
            colorScheme='green'  
            isLoading={values.isLoading}
            onClick={handleSubmit}
          >
            {values.idDevice===""?t("add"):t("update")}
          </Button>
        </Flex>
      </ModalFooter>
    </ModalContent>
  </Modal>
  );
}

export default withTranslation()(ModalForm);