import {
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,Input,
  Tr,Button,IconButton,
  useColorModeValue,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import MainMenu from "components/menu/MainMenu";
// Custom icons
import { IoEllipsisVertical } from "react-icons/io5";

import { withTranslation } from 'react-i18next';
function ColumnsTable(props) {
  const { columnsData, tableData,title,t,onSearch,onDelete } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { pageIndex, pageSize } = state;

  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandText = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
  const showAddBtn=()=>{
    let show=false
    if(props.hasOwnProperty("showAdd")){
      if(props.showAdd)show=true
    }else show=true

    if(show){
      return  <IconButton
        w='92px'
        h='35px'
        borderRadius='12px'
        aria-label='Search database'
        variant='no-hover'
        bg={bgButton}
        icon={<AddIcon w='12px' h='12px' color={brandText} />}
        onClick={() => {props.onOpen() }}
      />
    }else return <div/>
  }
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          {title}
        </Text>
        <Input
          variant='auth'
          fontSize='sm'
          width={200}
          mb='9px'
          fontWeight='500'
          placeholder={t("search")}
          onChange={(item)=>{onSearch(item.target.value);}}
          size='md'
        />
        {showAddBtn()}
       
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, indx) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={indx}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, indx) => {
                  let data = "";
                  if (cell.column.id === "title") 
                    data = (<Text color={textColor} fontSize='sm' fontWeight='700'>{cell.value} </Text>);
                  if (cell.column.id === "model_title") 
                    data = (<Text color={textColor} fontSize='sm' fontWeight='600'>{t(cell.value)} </Text>);
                  if (cell.column.id === "device_title") 
                    data = (<Text color={textColor} fontSize='sm' >{t(cell.value)} </Text>);
                  if (cell.column.id === "sensor_title") 
                    data = (<Text color={textColor} fontSize='sm' >{t(cell.value)} </Text>);
                  if (cell.column.id === "currency") 
                    data = (<Text color={textColor} fontSize='sm' >{t(cell.value?cell.value.toUpperCase():"")} </Text>);
                  if (cell.column.id === "id") {
                    data = (
                      <MainMenu
                        ms='auto'
                        mb='0px'
                        icon={
                          <Icon as={IoEllipsisVertical} w='24px' h='24px' color={textColor} />
                        }
                        onDelete={()=>{onDelete(cell.row.original)}}
                        onModify={()=>{props.onModify(cell.row.original)}}
                      />
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index+"_"+indx}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Flex mt='5px' justify='center' mb='2px' align='center'>
        <Button colorScheme='teal' size='xs' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>
        {" "}
        <Button colorScheme='teal' size='xs' onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"Previous"}
        </Button>
        {" "}
        <span>
          {" Page "}
          <strong>
            {pageIndex + 1} / {pageOptions.length}
          </strong>{" "}
        </span>
        {" "}
        <select
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {[10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <Button colorScheme='teal' size='xs' onClick={() => nextPage()} disabled={!canNextPage}>
          {"Next"}
        </Button>
        {" "}
        <Button colorScheme='teal' size='xs' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {">>"}
        </Button>
      </Flex>
    </Card>
  );
}
export default withTranslation()(ColumnsTable);