/*!
* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)
*/

import React from "react";
import { NavLink,useHistory } from "react-router-dom";
import {Buffer} from 'buffer';
import { GoogleReCaptchaProvider,GoogleReCaptcha } from 'react-google-recaptcha-v3';

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
  Link,
  useDisclosure } from '@chakra-ui/react'
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import "bootstrap/dist/css/bootstrap.css";
// Redux
import { useSelector,useDispatch } from 'react-redux';
///
import { withTranslation } from 'react-i18next';
import {validateEmail,validatePassword} from "utils/Validators";
import Constant from "utils/Constant";

import ForgotPassword from "./components/ForgotPassword";
import Validate2fa from "./components/Validate2fa";

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function SignIn(props) {
  const {t}=props;
  let history = useHistory();
  //init Redux
  const myInfos = useSelector(state => state.user.infos);
  const dispatch = useDispatch();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("secondary.dark", "white");

  const [showOTP, setShowOTP] = React.useState(false);
  const [show, setShow] = React.useState(false);
  var [infos, setInfos] =  React.useState({
    email:"",
    password:"",
    remember_me:false,
    isLoading:false
  });
  var [errorEmail, setErrorEmail] =  React.useState("");
  var [errorPwd, setErrorPwd] =  React.useState("");
  const [tokenReCaptcha, setTokenReCaptcha] = React.useState(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);


  React.useEffect(()=>{
    document.title ="Authentification - NeoLec";


    setTimeout(() => {
      let savedData=JSON.parse(localStorage.getItem("myInfos"))

      if(savedData){
        setInfos({...infos,remember_me:true})
        onLogin(savedData.email,Buffer.from(savedData.password, "base64").toString())
      }
      
      /*if(myInfos)
        if (myInfos.token) {
          onLogin(myInfos.email,Buffer.from(myInfos.password, "base64").toString())
        }*/
    }, 7000);
  },[])


  const handleClick = () => setShow(!show);

  const handleSubmit = async (e) => { 

    e.preventDefault();
    setRefreshReCaptcha(!refreshReCaptcha);

    let error,noFound=true;
    error= await validateEmail(infos.email)
    if(error.email)setErrorEmail("")
    else {
      setErrorEmail(error);
      noFound=false;
    }

    error= await validatePassword(infos.password)
    if(error.password)setErrorPwd("")
    else{
      setErrorPwd(error);
      noFound=false;
    }
    
    if(noFound) onLogin(infos.email,infos.password);
    //e.preventDefault();
  }

  const onLogin=(email,password_)=>{
    let password= Buffer.from(password_).toString('base64')
    
    setInfos({...infos,isLoading:true})
    //console.log({email,password,tokenReCaptcha});
    fetch(Constant.SERVER_URL+'/login_captcha', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({email,password,tokenReCaptcha})
    })
    .then(response => response.json())
    .then(responseData=>{
      setInfos({...infos,isLoading:false})
      if(responseData.hasOwnProperty("error")){
        if(responseData.error===200){
          localStorage.clear()
          if(infos.remember_me===true){
            localStorage.setItem('myInfos', JSON.stringify({email,password:password_}))
          }
          responseData.user.password=password_
          dispatch({type:'SET_INFOS',payload:responseData.user});
          if(responseData.user.hasOwnProperty("otp_enabled"))
            if(responseData.user.otp_enabled)setShowOTP(true)
            else history.push('/dashboard')
          else history.push('/dashboard')
        }else
          NotificationManager.error(responseData.msg, 'Message');
      }else{
        NotificationManager.error(t('unknown_error'), 'Message');
      }
    });
  }

  const handleChange = (prop) => (event) => {
    setInfos({ ...infos, [prop]: event.target.value });
  };
  

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Le0osgnAAAAADMP1Jk7xoMtP1CNmdad5o4Wx7Kb"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
        container={{ // optional to render inside custom element
          element: "body",
          parameters: {
            badge: 'bottomleft', // optional, default undefined inline|bottomright|bottomleft
            theme: 'light', // optional, default undefined light/dark
          }
        }}
      >
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
            {t("sign_in")}
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              {t("credential_sign_in")}
            </Text>
          </Box>
          <form>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl isRequired isInvalid={errorEmail!==""}>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                {t("email")}
              </FormLabel>
              <FormErrorMessage>{errorEmail}</FormErrorMessage>
              <Input
                id="email"
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='user@neolec.ch'
                mb='24px'
                fontWeight='500'
                value={infos.email}
                onChange={handleChange('email')}
                size='lg'
                isRequired={true}
              />
            </FormControl>

            <FormControl isRequired isInvalid={errorPwd!==""}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                {t("password")}
              </FormLabel>
              <FormErrorMessage>{errorPwd}</FormErrorMessage>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  id="password"
                  placeholder={t("password")}
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  value={infos.password}
                  onChange={handleChange('password')}
                  onKeyPress={e=> {
                    if (e.key === 'Enter') {
                      handleSubmit()
                    }
                  }
                }
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <GoogleReCaptcha 
              refreshReCaptcha={refreshReCaptcha}
              onVerify={token => {
                setTokenReCaptcha(token);
              }}
              />
            
            <FormControl>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                  <Checkbox
                    me='10px'
                    onChange={(e) => setInfos({...infos,remember_me:e.target.checked})}
                  />
                  <FormLabel
                    mb='0'
                    fontWeight='normal'
                    color={textColor}
                    fontSize='sm'>
                    {t("keep_me_log")}
                  </FormLabel>
                </FormControl>
                <NavLink to='#' onClick={() => {
                    onOpen()
                  }}>
                  <Text
                    color={textColorBrand}
                    fontSize='sm'
                    w='134px'
                    fontWeight='500'>
                    {t("forgot_password")+"?"}
                  </Text>
                </NavLink>
              </Flex>
              {tokenReCaptcha?<Button
                fontSize='sm'
                variant='custom'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                isLoading={infos.isLoading}
                onClick={handleSubmit}>
                {t("sign_in")}
              </Button>:<Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    {t("ReCaptcha ...")}
                  </Text>}
            </FormControl>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              {t("not_registered_yet")}
                <Link target="_blank" isExternal href="https://forms.gle/oxn9w2AZGuAcFNq48">
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    {t("order_system")}
                  </Text>
                </Link>
              </Text>
            </Flex>
          </Flex>
          </form>
          <Validate2fa
            isOpen={showOTP}
            user_id={""/*myInfos._id*/}
            setErrorMsg={(msg)=>{NotificationManager.error(msg, 'Message');}}
            setValidationOK={()=>{
              history.push('/dashboard')
            }}
            closeModal={()=>{setShowOTP(false)}}
          />
          <ForgotPassword
            isOpen={isOpen} onClose={onClose}
          />
          
        </Flex>
        
        <NotificationContainer/>
      </GoogleReCaptchaProvider>
    </DefaultAuth>

  );
}
export default withTranslation()(SignIn);