/*!
* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)
*/

import React from "react";
import { NavLink,useHistory } from "react-router-dom";
import {Buffer} from 'buffer';
import { GoogleReCaptchaProvider,GoogleReCaptcha } from 'react-google-recaptcha-v3';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  FormErrorMessage,
  useDisclosure } from '@chakra-ui/react'
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import "bootstrap/dist/css/bootstrap.css";
// Redux
import { useSelector,useDispatch } from 'react-redux';
///
import { withTranslation } from 'react-i18next';
import {validatePassword} from "utils/Validators";
import Constant from "utils/Constant";

import ForgotPassword from "./components/ForgotPassword";

import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function Sleep(props) {
  const {t}=props;
  let history = useHistory();
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("secondary.dark", "white");
  const textErrorBrand = useColorModeValue("red.600", "red.500");

  const [show, setShow] = React.useState(false);
  var [infos, setInfos] =  React.useState({
    password:"",
    remember_me:false,
    isLoading:false
  });
  var [errorPwd, setErrorPwd] =  React.useState("");
  const [tokenReCaptcha, setTokenReCaptcha] = React.useState(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);

  //init Redux
  const myInfos = useSelector(state => state.user.infos);
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title ="Authentification - NeoLec";
    dispatch({type:'SET_INFOS',payload:{firstname:myInfos.firstname,email:myInfos.email}});
   
}, []);


  const handleClick = () => setShow(!show);

  const handleSubmit = async (e) => { 
    e.preventDefault();
    setRefreshReCaptcha(!refreshReCaptcha);
    let error,noFound=true;

    error= await validatePassword(infos.password)
    if(error.password)setErrorPwd("")
    else{
      setErrorPwd(error);
      noFound=false;
    }
    
    if(noFound) onLogin(myInfos.email,infos.password);
    //e.preventDefault();
  }

  const onLogin=(email,password_)=>{
    let password= Buffer.from(password_).toString('base64')
    
    setInfos({...infos,isLoading:true})
    fetch(Constant.SERVER_URL+'/login_captcha', {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({email,password,tokenReCaptcha})
    })
    .then(response => response.json())
    .then(responseData=>{
      setInfos({...infos,isLoading:false})
      if(responseData.hasOwnProperty("error")){
        if(responseData.error===200){
          dispatch({type:'SET_INFOS',payload:responseData.user});
          history.push("/"+props.match.params.goto)
        }else NotificationManager.error(responseData.msg, 'Message');
      }else NotificationManager.error(t('unknown_error'), 'Message');
    });
  }

  const handleChange = (prop) => (event) => {
    setInfos({ ...infos, [prop]: event.target.value });
  };
  

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6Le0osgnAAAAADMP1Jk7xoMtP1CNmdad5o4Wx7Kb"
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: 'head', // optional, default to "head", can be "head" or "body",
          nonce: undefined // optional, default undefined
        }}
        container={{ // optional to render inside custom element
          element: "body",
          parameters: {
            badge: 'bottomleft', // optional, default undefined inline|bottomright|bottomleft
            theme: 'light', // optional, default undefined light/dark
          }
        }}
      >
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'
        >
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
            {t("welcome_back",{name:myInfos.firstname})}
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              {t("password_sleep_text")}
            </Text>
          </Box>

          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
           
            <FormControl isRequired isInvalid={errorPwd!==""}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                display='flex'>
                {t("password")}
              </FormLabel>
              <FormErrorMessage>{errorPwd}</FormErrorMessage>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  id="password"
                  placeholder={t("password")}
                  mb='24px'
                  size='lg'
                  type={show ? "text" : "password"}
                  variant='auth'
                  value={infos.password}
                  onChange={handleChange('password')}
                  onKeyPress={e=> {
                    if (e.key === 'Enter') {
                      handleSubmit()
                    }
                }}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <GoogleReCaptcha 
              refreshReCaptcha={refreshReCaptcha}
              onVerify={token => {
                setTokenReCaptcha(token);
              }}
              />
            
            <FormControl>
              <Flex justifyContent='space-between' align='center' mb='24px'>
                <div/>
                <NavLink to='#' onClick={() => {
                    onOpen()
                  }}>
                  <Text
                    color={textColorBrand}
                    fontSize='sm'
                    w='134px'
                    fontWeight='500'>
                    {t("forgot_password")+"?"}
                  </Text>
                </NavLink>
              </Flex>
              {tokenReCaptcha?<Button
                fontSize='sm'
                variant='custom'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                isLoading={infos.isLoading}
                onClick={handleSubmit}>
                {t("sign_in")}
              </Button>:<Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    {t("ReCaptcha ...")}
                  </Text>}
            </FormControl>
            <NavLink to='/logout'>
              <Text
                color={textErrorBrand}
                fontSize='sm'
                w='134px'
                fontWeight='500'>
                {t("logOut")}
              </Text>
            </NavLink>
          </Flex>
          <ForgotPassword isOpen={isOpen} onClose={onClose}/>
        </Flex>
        
        <NotificationContainer/>
      </GoogleReCaptchaProvider>
    </DefaultAuth>

  );
}
export default withTranslation()(Sleep);