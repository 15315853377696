
// Chakra imports
import { Input,
  useColorModeValue,Button,
  Flex,
  Table,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,IconButton} from "@chakra-ui/react";
import {useDisclosure} from "@chakra-ui/hooks";
// Custom components
//import SwitchField from "./SwitchField";
import * as R from 'ramda';
import {Post} from 'utils/queries'

import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import ModalAlertConfig from "./ModalAlertConfig";

import { AddIcon } from "@chakra-ui/icons";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import MainMenu from "components/menu/MainMenu";
// Custom icons
import { IoEllipsisVertical } from "react-icons/io5";

import { withTranslation } from 'react-i18next';

function Alerts(props) {
  const { ...rest } = props;
  const {myInfos,columnsData,title,t,cur_device,responseMsg,sensorsList } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const {isOpen, onOpen, onClose } = useDisclosure()
  
  var response;
  var [isLoading, setIsLoading] = React.useState(false);
  var [alert, setAlert] = React.useState({});


  var [alertList, setAlertList] = React.useState([]);
  var [alertAllList, setAlertAllList] = React.useState([]);
  const data = useMemo(() => alertList, [alertList]);
  
  // Chakra Color Mode
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandText = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  React.useEffect(() => {  
    (async function() { 
      updatListSensors() 
    })();
    return () => {}
  },[cur_device]);

  const updatListSensors= async ()=>{
    setIsLoading(true)
    
    response=await Post.requestPost("getMyAlerts",{token:myInfos.token})
    setAlertList(response)
    setAlertAllList(response)
    setIsLoading(false)
  } 

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  
  const showAddBtn=()=>{
    let show=false
    if(props.hasOwnProperty("showAdd")){
      if(props.showAdd)show=true
    }else show=true

    if(show){
      return  <IconButton
        w='92px'
        h='35px'
        borderRadius='12px'
        aria-label='Search database'
        variant='no-hover'
        bg={bgButton}
        icon={<AddIcon w='12px' h='12px' color={brandText} />}
        onClick={() => {
          setAlert({})
          onOpen()
        }}
      />
    }else return <div/>
  }
  const { pageIndex, pageSize } = state;


  const onSearch=(text)=>{
    if(text.length===0) setAlertList(alertAllList)
    else setAlertList(R.filter(o => JSON.stringify(o).toLowerCase().includes(text.toLowerCase()), alertAllList)) 
  }
  const deleteRow =async (row)=> {
    setIsLoading(true)
    response=await Post.requestPost("deleteAlert",{token:myInfos.token,id:row._id})
    if(response.hasOwnProperty("error")){
      if(response.error===200) {
        setAlertList(R.filter(o => o._id !== row._id, alertList))  
        setAlertAllList(R.filter(o => o._id !== row._id, alertAllList)) 
        NotificationManager.success(t("successful_operation"), "Message");
      }else if(response.hasOwnProperty("msg")) NotificationManager.error(response.msg, "Message")
      else NotificationManager.error(t("error_occurred"), "Message")
    }else NotificationManager.error(t("error_occurred"), "Message")
    setIsLoading(false)
  };

  const onDelete=(row)=>{
    NotificationManager.error(t("click_to_confirm"), t("deleting"), 5000, () => {
      deleteRow(row)
    });
  }

  const onModify=(row)=>{
    setAlert(row);
    onOpen()
  }

  return (
    <Card mb='20px' {...rest}>
      <LoadingOverlay {...rest}
        active={isLoading}
        spinner
        w='100%'
        text={t("loading")}
      >
        <Card
          direction='column'
          w='100%'
          px='0px'
          overflowX={{ sm: "scroll", lg: "hidden" }}>
          <Flex px='25px' justify='space-between' mb='20px' align='center'>
            <Text
              color={textColor}
              fontSize='22px'
              fontWeight='700'
              lineHeight='100%'>
              {title}
            </Text>
            <Input
              variant='auth'
              fontSize='sm'
              width={200}
              mb='9px'
              fontWeight='500'
              placeholder={t("search")}
              onChange={(item)=>{onSearch(item.target.value);}}
              size='md'
            />
            {showAddBtn()}
          
          </Flex>
          <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, indx) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe='10px'
                      key={indx}
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color='gray.400'>
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, indx) => {
                      let data = "";
                      if (cell.column.id === "alertTitle") 
                        data = (<Text color={textColor} fontSize='sm' fontWeight='700'>
                          {row.original.hasOwnProperty("alert")?row.original.alert.alertTitle:"----"} </Text>);
                      else if (cell.column.id === "alertType") 
                        data = (<Text color={textColor} fontSize='sm' >
                          {row.original.hasOwnProperty("alert")?t(row.original.alert.alertType.value):"----"} </Text>);
                      else if (cell.column.id === "_id") {
                        data = (
                          <MainMenu
                            ms='auto'
                            mb='0px'
                            icon={
                              <Icon as={IoEllipsisVertical} w='24px' h='24px' color={textColor} />
                            }
                            onDelete={()=>onDelete(cell.row.original)}
                            onModify={()=>onModify(cell.row.original)}
                          />
                        );
                      }
                      else data = (<Text color={textColor} fontSize='sm' >{t(cell.value)} </Text>);

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index+"_"+indx}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor='transparent'>
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <Flex mt='5px' justify='center' mb='2px' align='center'>
            <Button colorScheme='teal' size='xs' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </Button>
            {" "}
            <Button colorScheme='teal' size='xs' onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"Previous"}
            </Button>
            {" "}
            <span>
              {" Page "}
              <strong>
                {pageIndex + 1} / {pageOptions.length}
              </strong>{" "}
            </span>
            {" "}
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
            >
              {[10, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
            <Button colorScheme='teal' size='xs' onClick={() => nextPage()} disabled={!canNextPage}>
              {"Next"}
            </Button>
            {" "}
            <Button colorScheme='teal' size='xs' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {">>"}
            </Button>
          </Flex>
        </Card>

      {isOpen?<ModalAlertConfig
        isOpen={isOpen} 
        alert={alert}
        sensorsList={sensorsList}
        onClose={onClose}
        onSubmit={async(_alert)=>{
          _alert.ower_alert_id=myInfos._id
          setIsLoading(true)
          onClose()
          
          let stauts=false
          let _alertAllList=alertAllList
          for (let i = 0; i < _alertAllList.length; i++) {
            if(_alertAllList[i]._id===_alert._id){
              _alertAllList[i]=_alert
              
              setAlertAllList(_alertAllList)
              setAlertList(_alertAllList)

              response = await Post.requestPost("initAlert",{token:myInfos.token,alert:_alert})
              stauts=true
              if (response.hasOwnProperty("error")) 
                if(response.error===200)responseMsg(true,response.msg);
                else responseMsg(false,response.msg);
              else responseMsg(false,t('unknown_error'));
              break
            }
          }
          if(!stauts){
            response=await Post.requestPost("initAlert",{token:myInfos.token,alert:_alert})
            if (response.hasOwnProperty("error")) {
              if(response.error===200){
                let _id=response._id
                if(_alert.alert.alertType.value==="type_A3" || _alert.alert.alertType.value==="type_D3"){
                  if(_alert.alert.program){
                    _alert.alert.program["time slot"][_id]=_alert.alert.program["time slot"][_alert._id]
                    delete _alert.alert.program["time slot"][_alert._id]
                    _alert._id=_id
                  }
                }else _alert._id=_id
                setAlertAllList([...alertAllList,_alert])
                setAlertList([...alertList,_alert])
              }else responseMsg(false,response.msg);
            }else responseMsg(false,t('unknown_error'));
          }
          setIsLoading(false)
        }}
        showMsg={(msg)=>{responseMsg(true,msg); }}
        showErrorMsg={(msg)=>{responseMsg(false,msg); }}
      />:null}
      <NotificationContainer/>
      </LoadingOverlay>
    </Card>
  );
}
export default withTranslation()(Alerts);