// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import {useDisclosure} from "@chakra-ui/hooks";
import ComplexTable from "../users/components/ComplexTable";
import React from "react";
import { useHistory } from "react-router-dom";
// Redux
import {useDispatch,useSelector} from 'react-redux';

import {Post} from './queries'
import {Utils} from "utils";
import ModalForm from './components/ModalForm';

import * as R from 'ramda';
import LoadingOverlay from 'react-loading-overlay';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { withTranslation } from 'react-i18next';

var mexp = require('../../../utils/math-expression-evaluator');

function Sensor(props) {
  const {t}=props;
  let history = useHistory();
  const dispatch = useDispatch();
  var response;

  var [sensorsList, setSensorsList] = React.useState([]);
  var [sensorsListAll, setSensorsListAll] = React.useState([]);
  var [devicesList, setDevicesList] = React.useState([]);
  var [selectedItem, setSelectedItem] = React.useState([]);
  var [searchText, setSearchText] = React.useState("");
  

  const [values, setValues] = React.useState({
    id: "",
    full_id: "",
    device_id:"",
    title:"",
    subtitle:"",
    unit:"",
    val_min:0,
    val_max:100,
    type:"digital_sensor",
    isLoading:false
  });
  const myInfos = useSelector(state => state.user.infos);
  const cur_device = useSelector(state => state.device.cur_device);
  const exportedDevices = useSelector(state => state.device.exportedDevices);

  const { isOpen, onOpen, onClose } = useDisclosure()

  const columnsData = [
    {
      Header:"",
      accessor: "checkbox",
    },
    {
      Header: t("title"),
      accessor: "title",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: t("value"),
      accessor: "value",
    },
    {
      Header: t("last_message"),
      accessor: "last_message",
    },
    {Header: "Action", accessor: "id"},
  ];

  React.useEffect(() => {  
    (async function() {
      document.title = t("sensor")+"s - NeoLec";
      try{ 
        if(myInfos.token){
          listSensors()
          listDevices()
        }else history.push('/')
      }catch (e) {console.error(e);}
    })();
    return () => {}
  },[cur_device]);

  const listSensors= async ()=>{
    setValues({ ...values,"isLoading":true})
    response=await Utils.requestPost("listSensors",{token:myInfos.token,device_id:cur_device.value})
    setValues({ ...values,"isLoading":false})

    if(response.hasOwnProperty("error")) NotificationManager.error(response.error, 'Message');
    else{
      var _sensorsList=[]
      if(myInfos.role==="admin"){_sensorsList=response}
      else{
        response.forEach(elt => {
            _sensorsList.push(elt)
        });
      }

      for (let i = 0; i < _sensorsList.length; i++) {
        if(_sensorsList[i].formula)
          _sensorsList[i].value=parseExpresion(_sensorsList[i].formula,_sensorsList)[0]
      }

      setSensorsList(_sensorsList)
      setSensorsListAll(_sensorsList)
    }
  }
  
  const listDevices= async ()=>{
    setValues({ ...values,"isLoading":true})
    response=await Post.requestPost("listDevices",{token:myInfos.token})
    setValues({ ...values,"isLoading":false})
    if(response.hasOwnProperty("error")) NotificationManager.error(response.msg, 'Message');
    else{
      var all_devices=[]
      response.forEach(elt => {
        all_devices.push({label:elt.title,value:elt.id})
      });
      setDevicesList(all_devices)
    }
  }

  const deleteRow =async (row)=> {
    response=await Post.requestPost("deleteSensor",{token:myInfos.token,full_id:row._id})
    if(response.hasOwnProperty("error")){
      if(response.error===200){
        setSensorsList(R.filter(o => o._id !== row._id, sensorsList))  
        setSensorsListAll(R.filter(o => o._id !== row._id, sensorsListAll)) 
      }else NotificationManager.error(response.msg, 'Message');
    }else  NotificationManager.error(t("error_occurred"), 'Message'); 
  };

  const editRow =(row)=> {
    var graph={},forecast={},commands=null
    if(row.hasOwnProperty("graph")) graph=row.graph
    if(row.hasOwnProperty("forecast")) forecast=row.forecast
    if(row.hasOwnProperty("commands") && row.commands)  commands=row.commands
    
    setValues({ ...values, 
      id: row.id,
      full_id: row._id,
      device_id:row.device_id,
      device_label:R.filter(o=>o.value===row.device_id,devicesList)[0].label,
      title:row.title,
      subtitle:row.subtitle,
      unit:row.unit,
      val_min:row.val_min,
      val_max:row.val_max,
      input_val_min:row.input_val_min,
      input_val_max:row.input_val_max,
      no_integrate:row.no_integrate,
      diff_index:row.diff_index,
      formula:row.formula,
      type:row.type,
      graph,
      forecast,
      commands
    });
    if(row.hasOwnProperty("devices")) setValues({ ...values, myDevicesList: row.devices});
    onOpen()
  }

  const parseExpresion=(expression,list)=>{
    var text=expression, _var,_value
    if(expression)
      if(expression.includes("$")){
        var newTxt = text.split('$');
        for (var i = 1; i < newTxt.length; i++) {
          _var=newTxt[i].split(' ')[0];
          list.forEach(element => {
            if(element.id===_var){
              _value=element.value?element.value:"0"
              text=text.replace('$'+_var,''+_value)
            }
          });
        }
      }

    try{ return [text+" = "+mexp.eval(text),true,mexp.eval(text)]}
    catch(e){return [text+" = "+e.message,false,0] }
  }
  
  return (
    <LoadingOverlay
      active={values.isLoading}
      spinner
      text={t("loading")}
    >
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "220px", xl: "220px" }}>
        <ComplexTable
          title={''}
          columnsData={columnsData}
          tableData={sensorsList}
          selectedItem={selectedItem}
          onSelected={(state,item)=>{
            let _selectedItem=selectedItem
            if(state){
              _selectedItem.push(item)
            }else{
              for (let i = 0; i < _selectedItem.length; i++) {
                if(_selectedItem[i]._id===item._id){
                  _selectedItem.splice(i, 1)
                  break
                }
                
              }
            }
            setSelectedItem(_selectedItem)
          }}
          onExport={()=>{
            if(selectedItem.length>0){
              let _selectedItem=selectedItem
              for (let i = 0; i < _selectedItem.length; i++) {
                delete _selectedItem[i]._id
                delete _selectedItem[i].device_id
                delete _selectedItem[i].addBy
                delete _selectedItem[i].createdAt
              }
              dispatch({type:'EXPORTED_DEVICED',payload:_selectedItem});
              NotificationManager.success(t("Export completed"), 'Message');
            }else NotificationManager.info(t("No items selected"), 'Message');
          }}
          onImport={async ()=>{
            if(exportedDevices){
              if(exportedDevices.length>0){
                let response,_exportedDevices=exportedDevices
                for (let i = 0; i < _exportedDevices.length; i++) {
                  _exportedDevices[i].device_id=cur_device.value
                  _exportedDevices[i].token=myInfos.token
                  response=await Post.requestPost("addSensor",_exportedDevices[i])
                  if(response.hasOwnProperty("error")){
                    if(response.error===200){
                      _exportedDevices[i]._id=response.id
                      NotificationManager.success(t("successfully_added"), _exportedDevices[i].title);
                      
                      response=sensorsList
                      response.unshift(_exportedDevices[i])
                      setSensorsList(R.filter(o => JSON.stringify(o).toLowerCase().includes(searchText.toLowerCase()),response)) 
                      setSensorsListAll(response)
                    }
                    else if(response.error===402) NotificationManager.error(t("identifier_already_used", _exportedDevices[i].title))
                    else if(response.hasOwnProperty("msg")) NotificationManager.error(response.msg, "Message")
                    else NotificationManager.error(t("error_occurred"), "Message")
                  }else NotificationManager.error(t("error_occurred"), "Message")
                }
                NotificationManager.info(t("Successful import", "Message"))
                setTimeout(() => {window.location.reload();}, 2000);
              }else NotificationManager.info(t("No exports pending"), 'Message');
            }else NotificationManager.info(t("No exports pending"), 'Message');
          }}
          onDeleteAll={async()=>{
            if(selectedItem.length>0){
              for (let i = 0; i < selectedItem.length; i++) {
                response=await Post.requestPost("deleteSensor",{token:myInfos.token,full_id:selectedItem[i]._id})
                if(response.hasOwnProperty("error")){
                  if(response.error===200){
                    NotificationManager.info(t("deleting"), selectedItem[i].title);
                    setSensorsList(R.filter(o => o._id !== selectedItem._id, sensorsList))  
                    setSensorsListAll(R.filter(o => o._id !== selectedItem._id, sensorsListAll)) 
                  }else NotificationManager.error(response.msg, 'Message');
                }else  NotificationManager.error(t("error_occurred"), 'Message'); 
              }
              setTimeout(() => {window.location.reload();}, 2000);
            }else NotificationManager.info(t("No items selected"), 'Message');
          }}
          onDelete={(row)=>{
            NotificationManager.error(t("click_to_confirm"), t("deleting"), 5000, () => {
              deleteRow(row)
            });
          }}
          onSearch={(text)=>{
            setSearchText(text)
            if(text.length===0) setSensorsList(sensorsListAll)
            else setSensorsList(R.filter(o => JSON.stringify(o).toLowerCase().includes(text.toLowerCase()),sensorsListAll)) 
          }}
          onModify={(row)=>{editRow(row)}}
          onOpen={() => {
            setValues({...values,
              id: "",
              title:"",
              idDevice:"",
              full_id:"", 
              device_id:"",
              subtitle:"",
              unit:"",
              type:"",
              isLoading:false
            })
            onOpen()
          }}
        />
      </SimpleGrid>
        <ModalForm
          isOpen={isOpen} 
          onClose={onClose}
          token={myInfos.token}
          role={myInfos.role}
          data={values}
          devices={devicesList}
          sensorsList={sensorsListAll}
          addItem={(itm)=>{
            response=sensorsListAll
            if(itm.hasOwnProperty("full_id")){
              for (let i = 0; i < response.length; i++) {
                if(response[i]._id===itm.full_id){
                  itm.last_message=response[i].last_message
                  itm.value=response[i].value
                  response[i]=itm
                  break
                }}
            }
            else response.unshift(itm)
            setSensorsList(R.filter(o => JSON.stringify(o).toLowerCase().includes(searchText.toLowerCase()),response)) 
            setSensorsListAll(response)
          }}
          showMsg={(msg)=>{NotificationManager.success(msg, 'Message'); }}
          showErrorMsg={(msg)=>{NotificationManager.error(msg, 'Message'); }}
        />
      <NotificationContainer/>
    </Box>
    </LoadingOverlay>
  );
}
export default withTranslation()(Sensor);